import React, { Dispatch } from 'react';

// Type definitions
type Action = { type: 'setFlag'; cartFlag: boolean };
type State = { cartFlag: boolean };
type CountProviderProps = { children: React.ReactNode };

// Combining a reducer with context.
// Context API- Provides a simple way to share data between components without the need of passing props across all levels of you app tree.
// Reducer - Contains all the state update logic.

// Intitalizing context with the default state that'll be passed down to the components.
const CartStateContext = React.createContext<
  { state: State; dispatch: Dispatch<Action> } | undefined
>(undefined);

// Reducer - contains all the state update logic.
function cartReducer(state: State, action: Action) {
  switch (action.type) {
    case 'setFlag': {
      return {
        cartFlag: action.cartFlag,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Returns the Provider with the value set to include the items from the reducer - consuming component needs to be wrapped with CartProvider to make the value set available below the component tree.
function CartProvider({ children }: CountProviderProps) {
  const [state, dispatch] = React.useReducer(cartReducer, { cartFlag: false });
  const value = { state, dispatch };
  return (
    <CartStateContext.Provider value={value}>
      {children}
    </CartStateContext.Provider>
  );
}

// Creating a cart context hook so that a consuming component can import this function and just start using it.
function useCartContext() {
  const context = React.useContext(CartStateContext);
  if (context === undefined) {
    throw new Error('useCartContext must be used within a CartProvider');
  }
  return context;
}

export { CartProvider, useCartContext };
