import Script from 'next/script';

export default function Matomo() {
  return (
    <>
      <Script id="matomo">
        {`  var _mtm = window._mtm = window._mtm || [];
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        (function() {
          var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
          g.async=true; g.src='https://openmetrics.netnode.ch/js/container_lPVUDcdU.js'; s.parentNode.insertBefore(g,s);
        })();
        `}
      </Script>
    </>
  );
}
